<template>
  <v-card
    class="mx-n3 mx-sm-16 px-0 px-sm-4 px-md-8 flex-fill"
    max-width="420"
    flat
    tile
    outlined
  >
    <v-card-title class="my-3 my-sm-6 my-md-12 text-h4 font-weight-bold justify-center">
      {{ gc_langText.alteration.title[gc_lang] }}
    </v-card-title>

    <!--  이메일 찾기 결과  -->
    <v-card-text class="text-center mt-n8 pb-8">
      {{ gc_langText.alteration.article[gc_lang] }}
    </v-card-text>

    <!--  form  -->
    <v-card-text class="py-0">

      <!--  form 박스  -->
      <v-form
        ref="pwForm"
        v-model="pwFormValid"
      >

        <!--  새로운 비밀번호  -->
        <v-text-field
          class="rounded-0"
          v-model="form.pw"
          :label="gc_langText.alteration.pw[gc_lang]"
          @focus="form.pw = ''"
          @input="gm_form_isPwEqual"
          @click:append="show.pw = !show.pw"
          :rules="[gc_rules.required, gc_rules.pwLength, gc_rules.pwEqual]"
          :append-icon="show.pw ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show.pw ? 'text' : 'password'"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

        <!--  새로운 비밀번호 확인  -->
        <v-text-field
          class="rounded-0"
          v-model="form.pwr"
          :label="gc_langText.alteration.pwr[gc_lang]"
          @focus="form.pwr = ''"
          @input="gm_form_isPwEqual"
          @click:append="show.pwr = !show.pwr"
          @keypress.enter="gm_submitEnter"
          :rules="[gc_rules.required, gc_rules.pwLength, gc_rules.pwEqual]"
          :append-icon="show.pwr ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show.pwr ? 'text' : 'password'"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

      </v-form>

    </v-card-text>

    <!--  컨트롤러  -->
    <v-card-actions class="px-4 pt-0 flex-column">

      <v-btn
        ref="submit"
        class="mb-6 text-h6"
        @click="submit"
        :dark="!requesting"
        :disabled="requesting"
        :loading="requesting"
        elevation="0"
        color="cyan"
        block
        large
        tile
      >
        {{ gc_langText.alteration.change[gc_lang] }}
      </v-btn>

    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  name: "Alteration",

  data: () => ({
    // 폼 입력 데이터
    form: {
      pw: '',
      pwr: ''
    },

    // 비밀번호 입력란 보임 여부
    show: {
      pw: false,
      pwr: false
    },

    // 비밀번호 폼 입력 값들 유효 상태
    pwFormValid: true,

    // 요청 상태
    requesting: false,
  }),

  methods: {
    // 폼 입력값 전송
    submit() {
      this.$refs.pwForm.validate()

      if (this.pwFormValid) {
        this.api_changePasswordForce()
      }
    }
  }
}
</script>

<style lang="sass" scoped>

</style>